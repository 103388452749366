export default {
    "success": true,
    "company_rfm": {
        "total_clients": "2816",
        "segment_clients": "3890",
        "average_check": "6362.46",
        "r1_level": "99.14",
        "r2_level": "538",
        "r3_level": "758",
        "r4_level": "977.03",
        "segments": {
            "without_orders": {
                "index": 0,
                "total_clients": "682",
                "total_clients_percent": "24.22",
                "average_check": 0,
                "name": "Клиенты ни разу не совершавшие визит"
            },
            "newcomer": {
                "index": 1,
                "total_clients": "306",
                "total_clients_percent": "10.87",
                "average_check": "9167.59",
                "name": "Новички"
            },
            "growing": {
                "index": 2,
                "total_clients": "179",
                "total_clients_percent": "6.36",
                "average_check": "8342.58",
                "name": "Растущие"
            },
            "champions": {
                "index": 3,
                "total_clients": "296",
                "total_clients_percent": "10.51",
                "average_check": "7509.87",
                "name": "Чемпионы"
            },
            "doubting": {
                "index": 4,
                "total_clients": "344",
                "total_clients_percent": "12.22",
                "average_check": "7303.8",
                "name": "Сомневающиеся"
            },
            "loyal": {
                "index": 5,
                "total_clients": "139",
                "total_clients_percent": "4.94",
                "average_check": "6182.75",
                "name": "Лояльные"
            },
            "need_attention": {
                "index": 6,
                "total_clients": "15",
                "total_clients_percent": "0.53",
                "average_check": "5453.33",
                "name": "Требуют внимания"
            },
            "sleeping": {
                "index": 7,
                "total_clients": "335",
                "total_clients_percent": "11.9",
                "average_check": "6833.68",
                "name": "Спящие"
            },
            "on_the_brink": {
                "index": 8,
                "total_clients": "178",
                "total_clients_percent": "6.32",
                "average_check": "6588.8",
                "name": "Средние на грани"
            },
            "at_risk": {
                "index": 9,
                "total_clients": "188",
                "total_clients_percent": "6.68",
                "average_check": "5969.9",
                "name": "В зоне риска"
            },
            "outflow": {
                "index": 10,
                "total_clients": "1907",
                "total_clients_percent": "67.72",
                "average_check": "5339.2",
                "name": "Отток за всё время"
            },
            "not_come": {
                "index": 11,
                "total_clients": 0,
                "total_clients_percent": 0,
                "average_check": 0,
                "name": "Клиент не пришел"
            }
        },
        "total_s123_clients": "781",
        "s123_percent": "27.74",
        "s123_average_check": "8350.23",
        "expect_segments": {
            "total_expected_clients": "3",
            "total_expected_clients_percent": "0.11",
            "check": "63600",
            "segments": {
                "expect_newcomer": {
                    "index": 12,
                    "total_clients": "0",
                    "total_clients_percent": "0",
                    "check": "0",
                    "name": "Ожидаем Новичка"
                },
                "expect_growing": {
                    "index": 13,
                    "total_clients": "0",
                    "total_clients_percent": "0",
                    "check": "0",
                    "name": "Ожидаем Растущего"
                },
                "expect_champion": {
                    "index": 14,
                    "total_clients": "3",
                    "total_clients_percent": "0.11",
                    "check": "63600",
                    "name": "Ожидаем Чемпиона"
                }
            }
        }
    }
}