<template>
  <div
    class="reactivation-list"
    v-if="reactivationList && reactivationList.find((i) => i.goal)"
  >
    <div class="reactivation-list__row">
      <div class="reactivation-list__col">Статус</div>
      <div class="reactivation-list__col">Название</div>
      <div class="reactivation-list__col">Записался</div>
      <div class="reactivation-list__col">Пришел</div>
      <div class="reactivation-list__col">Оплатил</div>
      <div class="reactivation-list__col">Средний чек</div>
      <div class="reactivation-list__col">Выручка</div>
    </div>
    <div
      class="reactivation-list__row"
      :class="activation.open ? `_open` : ''"
      v-for="activation in reactivationList"
      :key="activation.id"
    >
      <div class="reactivation-list__col">{{ getStatus(activation) }}</div>
      <div class="reactivation-list__col">{{ activation.name }}</div>
      <div class="reactivation-list__col">
        {{ getOrderClients(activation) }}
      </div>
      <div class="reactivation-list__col">{{ getCameClients(activation) }}</div>
      <div class="reactivation-list__col">
        {{ getPaidClient(activation) }}
      </div>
      <div class="reactivation-list__col">
        {{
          activation.goal.average_check
            ? activation.goal.average_check.toLocaleString() + "₽"
            : "-"
        }}
      </div>
      <div class="reactivation-list__col">
        {{
          activation.goal.revenue
            ? activation.goal.revenue.toLocaleString() + "₽"
            : "-"
        }}
      </div>
      <div class="reactivation-list__col">
        <button
          class="reactivation-list__btn"
          @click="toggleReactivation(activation.id)"
        >
          <img :src="require('@/assets/images/reactivation-open.svg')" alt="" />
        </button>
      </div>

      <div class="reactivation-list__content reactivation-info">
        <div class="reactivation-info__header">
          <div class="reactivation-info__col">Параметры Кампании</div>
          <div class="reactivation-info__col">
            <span>Включить в Реактивацию всех клиентов сегмента</span>
            <span>Всем отправить по WhatsApp</span>
          </div>
          <div class="reactivation-info__col">
            <span>Интервал в который разрешена рассылка с 10:00 до 18:00</span>
            <span>Цель кампанании - Клиент пришёл и оплатил</span>
          </div>
        </div>
        <div class="reactivation-info__params">
          <div class="reactivation-info__row">
            <span>Кампания закончится через</span>
            <span>87 клиентов</span>
            <span>1 день и 7 часов </span>
          </div>
          <div class="reactivation-info__row">
            <span>Отслеживание цели</span>
            <span>13 клиентов</span>
            <span>28 дней 5 часов</span>
          </div>
        </div>
        <div class="reactivation-info_actions">
          <button class="btn _small _green" v-if="false">
            Возобновить рассылку
          </button>
          <button class="btn _small" v-else>Остановить рассылку</button>
          <button class="btn _small _red" @click="store.dispatch('reactivation/stopWatchingReactivation', activation.id)">Завершить досрочно</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

const reactivationList = computed(
  () => store.state.reactivation.reactivationList
);

function toggleReactivation(id) {
  reactivationList.value.forEach((element) => {
    if (element.id !== id) {
      element.open = false;
    } else {
      element.open = !element.open;
    }
  });
}

function getStatus(activation) {
  if (activation.goal.is_active) {
    return 'Активна'
  } else {
    return 'Не активна'
  }
}
function getClientsInfo(activation, countKey, percentKey) {
  if (typeof activation.goal[countKey] === "number") {
    return (
      activation.goal[countKey] + " / " + ((activation.goal[percentKey] ? activation.goal[percentKey] + '%' : false) || '0%')
    );
  } else {
    return "- / -";
  }
}

function getOrderClients(activation) {
  return getClientsInfo(
    activation,
    "signed_up_clients_count",
    "signed_up_clients_percent"
  );
}

function getCameClients(activation) {
  return getClientsInfo(
    activation,
    "came_clients_count",
    "came_clients_percent"
  );
}

function getPaidClient(activation) {
  return getClientsInfo(
    activation,
    "paid_clients_count",
    "paid_clients_percent"
  );
}

const store = useStore();
</script>

<style lang="scss" scoped>
.reactivation-list {
  max-width: 1384px;
  border: solid 2px #e5e7ed;
  border-radius: 20px;

  &__btn {
    cursor: pointer;
    transform: rotate(0deg);
    transition: 0.2s;
    padding: 20px;
    margin: -20px;
  }
  &__row {
    display: grid;
    grid-template-columns: 1fr 9fr 3fr 3fr 2fr 3fr 2fr 0.4fr;
    grid-template-rows: 1fr 0px;
    grid-column-gap: 24px;
    padding: 23px;
    border-bottom: solid 2px #e5e7ed;
    font-size: 16px;
    cursor: default;
    transition: 0.2s;

    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      font-size: 18px;
      font-weight: 500;
    }
  }
  &__content {
    padding-top: 37px;
    overflow: hidden;
    grid-column: 1 / 9;
  }

  &__row._open {
    grid-template-rows: 1fr 267px;
  }

  &__row._open &__btn {
    transform: rotate(180deg);
  }

  &__col {
    font-weight: 500;
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(5) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.reactivation-info {
  display: grid;
  grid-template-rows: 1fr 1.2fr;
  grid-template-columns: 2fr 445px;
  row-gap: 29px;
  &_actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 23px;
    grid-template-rows: 41px;

    .btn {
      grid-column: 2/3;
    }
  }

  &__header {
    grid-row: 1;
    grid-column: 1/3;

    display: grid;
    grid-template-columns: 1.3fr 1.7fr 2.3fr 1fr;
    background: #cdf7ff;
    padding: 30px;
    border-radius: 12px;
    font-weight: 500;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    span {
      font-size: 12px;
    }
  }

  &__params {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
  }

  &__row {
    display: flex;

    span:nth-child(1) {
      width: 35%;
    }
    span:nth-child(2) {
      width: 20%;
    }
  }
}
</style>
