<template>
  <div class="segment-recomendation">
    <div class="segment-recomendation__info">
      <div
        class="recomendation-block"
        :style="`background-color:${currentSegment.background_color}`"
      >
        <div class="recomendation-block__index">
          <span class="base-segment__number">{{ currentIndex }}</span>
          <img
            class="base-segment__smile"
            :src="
              require('@/assets/images/segments-smiles/' +
                currentSegment.smileAnimation)
            "
            alt=":)"
          />
        </div>
        <div class="recomendation-block__name">{{ currentSegment.name }}</div>
      </div>
      <div
        class="recomendation-block"
        :style="`background-color:${currentSegment.background_color}`"
      >
        <div class="recomendation-block__title">Статистика по сегменту*</div>
        <div class="recomendation-block__row">
          Клиентов - <span> {{ total_clients.toLocaleString() }}</span>
        </div>
        <div class="recomendation-block__row">
          От общего числа -
          <span> {{ currentSegment.total_clients_percent + "%" }} </span>
        </div>
        <div
          class="recomendation-block__row"
          v-if="currentSegment.average_check"
        >
          Средний чек -
          <span>
            {{ Math.trunc(+currentSegment.average_check).toLocaleString() }} ₽
          </span>
        </div>

        <div class="recomendation-block__ps">*На сейчас</div>
      </div>
      <div
        class="recomendation-block"
        :style="`background-color:${currentSegment.background_color}`"
      >
        <div class="recomendation-block__title">Параметры сегмента*</div>
        <div
          class="recomendation-block__row"
          v-if="currentSegment.min_recency >= 0"
        >
          Давность визитов -

          <span v-if="currentSegment.min_recency >= 0">
            от {{ getRecency(currentSegment.min_recency) }} до
            {{ getRecency(currentSegment.max_recency) }} дней
          </span>
        </div>

        <div class="recomendation-block__row" v-if="currentSegment.visits">
          Количество визитов -
          <span> {{ currentSegment.visits }} </span>
        </div>

        <div class="recomendation-block__ps">
          *Рассчитывается автоматически при помощи алгоритма ИИ
        </div>
      </div>
    </div>

    <div class="segment-recomendation__btns">
      <button @click="hideSegments" class="btn _gray">
        Закрыть без изменений
      </button>
      <button
        @click="isClientShow = !isClientShow"
        :class="isClientShow ? '_blue' : '_green'"
        class="btn"
      >
        Показать {{ isClientShow ? "рекомендации" : "клиентов" }}
      </button>
      <button
        class="btn"
        style="border-top-left-radius: 0"
        @click="goReactivation"
      >
        Реактивация
      </button>
    </div>

    <iframe
      v-show="!isClientShow"
      ref="iframeRef"
      :src="`https://b24-7zji69.bitrix24site.ru/${currentSegment.key}/`"
      class="segment-recomendation__iframe"
      frameborder="0"
    ></iframe>
    <segmentClients
      v-if="isClientShow"
      class="segment-recomendation__clients"
    ></segmentClients>
  </div>
</template>

<script lang="ts" setup>
import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import segmentClients from "@/components/main-page/segments/segmentClients.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

type typeProps = {
  segment: TypeSegment;
};
/* eslint-disable-next-line */
const props = defineProps<typeProps>();
const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const currentIndex = computed(() => {
  const index = currentSegment.value.index;
  if (typeof index === "number") {
    if (index < 10) {
      return "0" + index;
    } else {
      return index;
    }
  } else {
    return index;
  }
});

const isClientShow = ref(false);

const total_clients = computed(() => +props.segment.total_clients);

const company_rfm = computed(() => store.state.segments.company_rfm);

function hideSegments() {
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}

function getRecency(recency) {
  const number = +recency;

  let result = 0;
  if (number === 0) {
    return result;
  }
  if (number === 51.5) {
    result = +company_rfm.value.r1_level;
  }

  if (number === 90) {
    result = +company_rfm.value.r2_level;
  }
  if (number === 125) {
    result = +company_rfm.value.r3_level;
  }
  if (number === 160) {
    result = +company_rfm.value.r4_level;
  }

  return result.toFixed();
}

function goReactivation() {
  isGoingReactivation.value = true;

  router.push("/reactivation");
}
const isGoingReactivation = ref(false);

onUnmounted(() => {
  if (isGoingReactivation.value) {
    store.state.segments.currentSettingSegment = null;
  } else {
    store.state.segments.currentSelectSegment = null;
    store.state.segments.currentSettingSegment = null;
  }
});
</script>

<style lang="scss" scoped>
.segment-recomendation {
  padding-top: 12px;
  display: grid;
  grid-template-columns: auto 240px 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 19px;
  &__iframe {
    grid-row: 2;
    grid-column: 1 / 3;
    width: 100%;
    height: 350vh;
    margin-left: 19px;
  }
  &__clients {
    grid-row: 2;
    grid-column: 1 / 3;
    margin-top: 35px;
    margin-left: 36px;
  }

  &__info {
    display: inline-flex;
    gap: 7px;
    border: solid 1px #cccccc;
    padding: 5px;
    border-radius: 19px;
    justify-self: start;
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 6px 0;
    grid-column: 2/4;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    align-items: center;
    column-gap: 40px;
    min-width: 515px;
    max-width: 520px;
    ._gray {
      grid-column: 2;
      align-self: start;
    }
    ._green,
    ._blue {
      grid-column: 1;
      grid-row: 1;
      align-self: start;
    }
    a {
      align-self: end;
    }
  }
}
.recomendation-block {
  padding: 20px 30px;
  min-height: 148px;
  position: relative;

  &__index {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }

  &:nth-child(1) {
    border-radius: 15px 0 0 15px;
    min-width: 142px;
    padding: 30px;
  }
  &:nth-child(2) {
    min-width: 267px;
  }
  &:nth-child(3) {
    border-radius: 0 15px 15px 0;
    min-width: 390px;
  }
  &__number {
  }

  &__emoji {
  }

  &__name {
    max-width: 165px;
  }

  &__title {
    font-weight: 500;
    margin-bottom: 14px;
  }

  &__row {
    margin-bottom: 10px;

    span {
      font-weight: 500;
    }
  }

  &__ps {
    position: absolute;
    bottom: 7px;
    right: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    letter-spacing: 0.3px;
  }
}
</style>
