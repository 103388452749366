<template>
  <div class="reactivation-template">
    <div class="reactivation-template__header">
      <uiAccordion :height="200" :defaultOpen="true" class="header-accordion">
        <template v-slot:header>
          <div class="reactivation-template__warn">
            Важные рекомендации для безопасной рассылки в WhatsApp:

            <span class="reactivation-accordion__toggle"></span>
          </div>
        </template>
        <template v-slot:content>
          <div class="content">
            <ol class="reactivation-template__rools">
              <li v-for="(rool, index) in sendAdvices.list" :key="index">
                <span class="fw-500">{{ rool.name }}</span>
                {{ rool.text }}
              </li>
            </ol>
            <div class="fw-500">
              Соблюдение этих рекомендаций поможет снизить риски блокировки
              аккаунта и повысить эффективность рассылки.
            </div>
          </div>
        </template>
      </uiAccordion>
    </div>
    <uiAccordion class="reactivation-accordion" :height="1103">
      <template v-slot:header>
        <div class="reactivation-accordion__header">
          Подсказка: как написать сообщение, на которое сложно не ответить.
          Нажмите, чтобы узнать больше.

          <span class="reactivation-accordion__toggle"></span>
        </div>
      </template>
      <template v-slot:content>
        <div class="reactivation-accordion__content">
          <ol class="reactivation-template__rools">
            <li v-for="advice in templateAdvices.list" :key="advice.name">
              <span class="fw-500">{{ advice.name }}</span> <br />
              <div v-html="advice.text"></div>
              <div class="reactivation-template__example">
                "{{ advice.example }}"
              </div>
            </li>
          </ol>

          <div class="reactivation-accordion__last">
            <div class="fw-500" v-html="templateAdvices.lastBlock.name"></div>
            <div
              class="reactivation-accordion__text"
              v-html="templateAdvices.lastBlock.text"
            ></div>
          </div>

          <div
            class="reactivation-accordion__footer"
            v-html="templateAdvices.footerText"
          ></div>
        </div>
      </template>
    </uiAccordion>
    <div class="reactivation-template__block">
      <reactivationTemplateForm></reactivationTemplateForm>
    </div>
    <div class="reactivation-template__block">
      <reactivationTestForm></reactivationTestForm>
    </div>
    <div class="reactivation-template__block">
      <button
        class="btn _gray"
        @click="store.state.reactivation.reactivationCreateStep = 0"
      >
        Назад
      </button>
      <button class="btn" @click="saveAndGoReactivation">
        Сохранить и запустить рассылку
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import emojiButton from "@/components/reactivation/emojiButton.vue";
import reactivationTemplateForm from "@/components/reactivation/reactivationTemplateForm.vue";
import reactivationTestForm from "@/components/reactivation/reactivationTestForm.vue";
import uiAccordion from "@/components/UI/ui-accordion.vue";
import { computed, onUnmounted, reactive, ref } from "vue";

import { templateAdvices, sendAdvices } from "@/assets/texts";

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const channels = ref(["Push", "WhatsApp"]);

function inputRedaction(channel, event) {
  console.dir(event);
}

let activeChannel = ref("Push");

const templates = reactive({
  Push: { text: "" },
  WhatsApp: { text: "" },
});

function saveAndGoReactivation() {
  store.state.reactivation.reactivationCreateStep = 2;
}

function closeReactivation() {
  store.state.segments.currentSelectSegment = null;
  store.state.segments.currentSettingSegment = null;
}
</script>

<style lang="scss" scoped>
.header-accordion {
  &._open {
    .reactivation-accordion__toggle {
      transform: rotate(-180deg) translateY(1px);
    }

    border-bottom: 1px solid #e5e7ed;
  }
}
.reactivation-template {
  border: 1px solid #e5e7ed;
  border-radius: 12px;
  max-width: 887px;

  &__header {
    background: rgba(221, 96, 104, 0.09);
    font-size: 14px;
        border-radius: 11px 11px 0 0;
    .content {
      padding: 0 34px;
    }
  }

  &__rools {
    counter-reset: item;
    margin-top: 10px;
    padding-left: 20px;

    li {
      position: relative;
      margin-bottom: 7px;
      line-height: 1.5;
      text-align: justify;
    }

    li::before {
      content: counter(item) ".";
      counter-increment: item;
      font-weight: 500; /* Жирность номеров */
      position: absolute;
      left: -20px;
    }
  }
  &__example {
    font-style: italic;
    font-weight: 500;
    margin: 10px 0 15px 0;
  }

  &__warn {
    font-size: 16px;
    font-weight: 500;
    color: #dd6068;
    padding: 23px 34px;
    display: flex;
    border-bottom: 1px solid #e5e7ed;

    align-items: center;
    justify-content: space-between;
  }
  &__block {
    border-bottom: 1px solid #e5e7ed;
  }

  &__block:last-child {
    display: flex;
    justify-content: space-between;
    padding: 35px 34px;
  }
}

.reactivation-accordion {
  background: #f3f4f7;

  &._open {
    .reactivation-accordion__toggle {
      transform: rotate(-180deg) translateY(1px);
    }
    border-bottom: 1px solid #e5e7ed;
  }

  &__toggle {
    background: url(~@/assets/images/reactivation-open.svg) no-repeat center /
      80%;
    width: 18px;
    height: 9px;
    transition: 0.2s;
  }

  &__header {
    min-height: 63px;
    padding: 0 34px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
  }

  &__content {
    padding: 20px 34px;
  }

  ol {
    margin-bottom: 20px;
  }

  &__text {
    line-height: 1.5;
  }

  &__footer {
    font-weight: 500;
    line-height: 1.5;
    margin-top: 20px;
  }
  &__last {
    .fw-500 {
      margin: 20px 0;
    }
  }
}
</style>
